import React from "react";

function Header() {
  // Function to handle smooth scrolling with offset
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const headerHeight = document.querySelector("header").offsetHeight; // Get the height of the header
      const offsetTop = element.offsetTop - headerHeight; // Subtract the height of the header from the target element's offset top
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <header className="bg-white shadow fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className="flex items-center">
          {/* Logo */}
          <img src="/logo.png" alt="Logo" className="h-24 w-auto mr-2" />

          {/* Brand name, with responsive text sizing */}
          <h1 className="text-xl sm:text-2xl font-bold text-gray-800 sm:block hidden flex flex-col sm:flex-row">
            <span>Ahmed's </span>
            <span>Touch</span>
          </h1>
        </div>
        <nav className="flex-shrink-0">
          <ul className="flex space-x-2 md:space-x-4">
            <li>
              <button
                onClick={() => scrollToSection("home")}
                className="text-gray-600 hover:text-gray-800"
              >
                Home
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection("services")}
                className="text-gray-600 hover:text-gray-800"
              >
                Services
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection("about")}
                className="text-gray-600 hover:text-gray-800"
              >
                About
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection("contact")}
                className="text-gray-600 hover:text-gray-800"
              >
                Contact
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
