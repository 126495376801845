import React from "react";

function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-6 px-4">
      <div className="container mx-auto text-center">
        <p>
          &copy; {new Date().getFullYear()} Ahmed's Touch Barbershop. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
