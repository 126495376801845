import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <div className="bg-gray-100">
      <Header />
      <div className="pt-20">
        {/* Add padding top equal to the height of your header */}
        <section id="home" className="bg-gray-900 text-white py-20 px-4">
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-bold mb-4">
              Welcome to Your Barbershop
            </h2>
            <p className="text-lg mb-8">
              Get the best haircut in town from our experienced barbers.
            </p>
            <button className="bg-white text-gray-900 py-2 px-6 rounded-full font-bold text-lg hover:bg-gray-100 hover:text-gray-900">
              <a
                href="https://booksy.com/en-us/149017_ahmed-s-touch-barbershop_barber-shop_102460_temecula"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book Now
              </a>
            </button>
            <p className="text-md mt-10 mb-2">
              Walk-Ins Welcome — Subject to Availability
            </p>
            <p className="text-md mt-1">
              <a
                href="https://www.google.com/maps/dir/?api=1&destination=27309+Jefferson+Avenue+%23STE+106+Temecula%2C+CA+92590"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 underline"
              >
                27309 Jefferson Avenue #STE 106 Temecula, CA 92590
              </a>
            </p>
          </div>
        </section>
        {/* Services Section */}
        <section id="services" className="bg-gray-100 py-20 px-4">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center mb-8">
              Our Services
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Haircut Service */}
              <div className="p-6 bg-white rounded-lg shadow-md relative">
                {/* Image */}
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src="/haircut.png"
                    alt="Haircut"
                    className="w-full h-auto"
                  />
                  {/* Title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 ml-2 p-1 text-black">
                    <h3 className="text-xl font-semibold">Haircut</h3>
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Get the perfect haircut tailored to your style.
                  </p>
                </div>
              </div>
              {/* Haircut and Beard Service */}
              <div className="p-6 bg-white rounded-lg shadow-md relative">
                {/* Image */}
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src="/haircutbeard.png"
                    alt="Haircut"
                    className="w-full h-auto"
                  />
                  {/* Title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 ml-2 p-1 text-black">
                    <h3 className="text-xl font-semibold">Beard</h3>
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Achieve a polished look with a beard grooming.
                  </p>
                </div>
              </div>

              {/* Kids Haircut Service */}
              <div className="p-6 bg-white rounded-lg shadow-md relative">
                {/* Image */}
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src="/kidshaircut.png"
                    alt="Haircut"
                    className="w-full h-auto"
                  />
                  {/* Title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 ml-2 p-1 text-black">
                    <h3 className="text-xl font-semibold">Kids Haircut</h3>
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Make your little one look their best with our kids haircut
                    service.
                  </p>
                </div>
              </div>

              {/* Dye Hair Service */}
              <div className="p-6 bg-white rounded-lg shadow-md relative">
                {/* Image */}
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src="/dye-hair.png"
                    alt="Haircut"
                    className="w-full h-auto"
                  />
                  {/* Title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 ml-2 p-1 text-black">
                    <h3 className="text-xl font-semibold">Dye Hair</h3>
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Transform your hair color with our professional dyeing
                    service.
                  </p>
                </div>
              </div>

              {/* Facial Treatment Service */}
              <div className="p-6 bg-white rounded-lg shadow-md relative">
                {/* Image */}
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src="/facial.png"
                    alt="Facial Treatment"
                    className="w-full h-auto"
                  />
                  {/* Title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 ml-2 p-1 text-black">
                    <h3 className="text-xl font-semibold">Facial</h3>
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Revitalize your skin with our rejuvenating facial
                    treatments.
                  </p>
                </div>
              </div>

              {/* Special Service (Bald Head) */}
              <div className="p-6 bg-white rounded-lg shadow-md relative">
                {/* Image */}
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src="/bald.png"
                    alt="Special Bald Treatment"
                    className="w-full h-auto"
                  />
                  {/* Title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 ml-2 p-1 text-black">
                    <h3 className="text-xl font-semibold">Special Service</h3>
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Experience personalized care for a smooth and polished bald
                    head look.
                  </p>
                </div>
              </div>

              {/* Hair Wash Service */}
              <div className="p-6 bg-white rounded-lg shadow-md relative">
                {/* Image */}
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src="/hair-wash.png"
                    alt="Hair Wash"
                    className="w-full h-auto"
                  />
                  {/* Title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 ml-2 p-1 text-black">
                    <h3 className="text-xl font-semibold">Hair Wash</h3>
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Indulge in a relaxing hair wash and scalp treatment
                    experience.
                  </p>
                </div>
              </div>

              {/* Eyebrow Threading Service */}
              <div className="p-6 bg-white rounded-lg shadow-md relative">
                {/* Image */}
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src="/eyebrows.png"
                    alt="Threading"
                    className="w-full h-auto"
                  />
                  {/* Title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 ml-2 p-1 text-black">
                    <h3 className="text-xl font-semibold">Eyebrow Threading</h3>
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Achieve defined and sculpted eyebrows with our threading
                    service.
                  </p>
                </div>
              </div>

              {/* Ears, Nose, and Cheek Waxing Service */}
              {/* Eyebrow Threading Service */}
              <div className="p-6 bg-white rounded-lg shadow-md relative">
                {/* Image */}
                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src="/waxing.png"
                    alt="Threading"
                    className="w-full h-auto"
                  />
                  {/* Title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 ml-2 p-1 text-black">
                    <h3 className="text-xl font-semibold">Waxing</h3>
                  </div>
                </div>
                {/* Description */}
                <div className="mt-4">
                  <p className="text-gray-700 leading-relaxed">
                    Experience smooth and precise waxing for facial hair
                    removal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* About Section */}
        <section id="about" className="py-20 px-6">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold flex items-center justify-center mb-8">
              About Us
              <a
                href="https://www.instagram.com/ahmedstouchbr/"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-4"
              >
                <img src="/logo-ig.png" alt="Instagram" className="w-6 h-6" />
              </a>
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed mb-4">
              Welcome to Ahmed's Touch, where every haircut is a work of art and
              every customer is family. Nestled in the heart of beautiful
              Temecula, our barbershop stands as a beacon of tradition and
              craftsmanship.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-4">
              With years of experience and a passion for our craft, we take
              pride in providing more than just haircuts – we offer an
              experience that leaves you feeling rejuvenated, confident, and
              ready to conquer the world.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-4">
              Our commitment to excellence extends beyond the chair. We believe
              in creating meaningful connections with each of our clients,
              understanding their unique style preferences, and tailoring our
              services to exceed their expectations every time they walk through
              our doors.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-4">
              From classic cuts to modern styles, our skilled barbers are
              dedicated to delivering precision, professionalism, and
              unparalleled service with every snip of the scissors. Our warm and
              inviting atmosphere invites you to relax, unwind, and enjoy the
              artistry of grooming in its purest form.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-4">
              In order to ensure the best experience for all our valued
              customers, we kindly request that appointments be made in advance.
              This allows us to dedicate sufficient time to each client and
              maintain a smooth workflow. We appreciate your understanding and
              cooperation in helping us provide exceptional service.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed">
              Whether you're a regular or stepping into our barbershop for the
              first time, we welcome you with open arms and a promise to make
              your experience unforgettable. Thank you for choosing Ahmed's
              Touch – where every haircut tells a story, and every visit feels
              like coming home.
            </p>
          </div>
        </section>
        {/* Contact Section */}
        <section id="contact" className="py-20 px-4">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center mb-8">Contact Us</h2>
            <p className="text-lg text-gray-700 leading-relaxed mb-4">
              At Ahmed's Touch, we are committed to providing the best and
              cleanest barber services in Temecula. Your satisfaction and
              comfort are our top priorities. If you have any questions or
              inquiries about our services, appointments, or anything else,
              please feel free to reach out to us at{" "}
              <a
                href="mailto:ahmedtouchbr@gmail.com"
                className="text-blue-600 hover:underline"
              >
                ahmedtouchbr@gmail.com
              </a>
              . We are here to assist you!
            </p>
            {/* Add your contact form or details here */}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
